import Head from 'next/head';
import React from 'react';
import { GuideBrowseGroup } from '@/domain/guide/GuideBrowseGroup';
import { GROUP_LIST } from '@/domain/guide/_common/constants';
import type {
  GetStaticPaths,
  GetStaticProps,
  InferGetServerSidePropsType,
} from 'next';

export default function Page(
  props: InferGetServerSidePropsType<typeof getStaticProps>
) {
  return (
    <React.Fragment>
      <Head>
        <title>
          {props.groupItemData.name} - よくある質問 | U-NEXTヘルプセンター
        </title>
      </Head>
      <GuideBrowseGroup {...props} />
    </React.Fragment>
  );
}

export const getStaticProps: GetStaticProps<
  React.ComponentProps<typeof GuideBrowseGroup>
> = async ({ params }) => {
  const groupItemData = GROUP_LIST.find(
    ({ code }) => code === (params?.groupCode as string)
  );

  if (!groupItemData) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      groupItemData,
    },
  };
};

export const getStaticPaths: GetStaticPaths = () => ({
  paths: [],
  fallback: 'blocking',
});
