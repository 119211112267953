import { GlobalConfig } from '@/configs/globalConfig';
import { BigBarWithSearchGuide } from '@/localShared/components/BigBarWithSearchGuide';
import { Spacer } from '@/shared/components/atoms/Spacer';
import { Breadcrumbs } from '@/shared/components/molecules/Breadcrumbs';
import type { MenuItem } from '@/shared/components/molecules/MenuListCard';
import { MenuListCard } from '@/shared/components/molecules/MenuListCard';
import { BaseLayout } from '@/shared/components/templates/BaseLayout';
import { CATEGORY_MAP } from '../_common/constants';
import type { GroupItemData } from '../_common/constants';

export const GuideBrowseGroup: React.FC<{
  groupItemData: GroupItemData;
}> = ({ groupItemData }) => (
  <BaseLayout options={GlobalConfig}>
    <BigBarWithSearchGuide title="よくある質問" />
    <Spacer level={1} />
    <Breadcrumbs
      list={[
        {
          href: '/',
          label: 'ヘルプセンター',
        },
        {
          href: '/guide',
          label: 'よくある質問',
        },
        {
          href: `/guide/browse/${groupItemData.code}`,
          label: groupItemData.name,
        },
      ]}
    />
    <Spacer level={3} />
    <section>
      <MenuListCard
        title={groupItemData.name}
        menus={groupItemData.category
          .filter((categoryCode) => CATEGORY_MAP[categoryCode])
          .map(
            (categoryCode): MenuItem => ({
              url: `/guide/browse/${groupItemData.code}/${categoryCode}`,
              title: CATEGORY_MAP[categoryCode].name,
              text: CATEGORY_MAP[categoryCode].text,
            })
          )}
      />
    </section>
  </BaseLayout>
);
