
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/guide/browse/[groupCode]",
      function () {
        return require("private-next-pages/guide/browse/[groupCode]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/guide/browse/[groupCode]"])
      });
    }
  